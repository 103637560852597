import { Icon } from 'antd'
import { IconProps } from 'antd/lib/icon'
import { Dispatch } from 'easy-peasy'
import React from 'react'
import { MenuItem, Nav, Navbar, NavDropdown, NavItem } from 'react-bootstrap'
import { FaLock, FaSignOutAlt } from 'react-icons/fa'
import { MdLanguage } from 'react-icons/md'
import { connect } from 'react-redux'
import { RouteChildrenProps } from 'react-router'
import { LinkContainer } from 'react-router-bootstrap'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { AppRoutes } from '../AppRoutes'
import { graphTranslations } from '../Diagrams/graphTranslations'
import { switchLanguage } from '../store/actions/LanguageActions'
import { IApplicationModel } from '../store/store'
import polyglot from '../Translator'
import './Navbar.scss'

const MenuIcon = styled<IconProps>(props => <Icon {...props} />)`
  font-size: 18px;
  padding-right: 8px;
`

const EarthIcon = styled(MdLanguage)`
  margin-right: 3px;
`

function RedoxNavbar({ userProfile, switchLanguage, location, signOut }: INavbarProps & RouteChildrenProps) {
  return (
    !location.pathname.includes(AppRoutes.FILL_DIAGRAMS) && (
      <Navbar id="navbar" collapseOnSelect fluid fixedTop>
        <Navbar.Header>
          <Link to={AppRoutes.HOME}>
            <img alt="" className="logo" src={require('../img/logo-dark.svg')} />
          </Link>
          <Navbar.Toggle className="toggle" />
        </Navbar.Header>
        <Navbar.Collapse>
          {!userProfile && (
            <Nav>
              <LinkContainer to={AppRoutes.ABOUT}>
                <NavItem>{polyglot.t('About')}</NavItem>
              </LinkContainer>

              <LinkContainer to={AppRoutes.PRICING}>
                <NavItem>{polyglot.t('pricingProduct')}</NavItem>
              </LinkContainer>
              {/* <LinkContainer to={AppRoutes.TUTORIALS}>
                <NavItem>{polyglot.tr(tutorialsTranslations.tutorial)}</NavItem>
              </LinkContainer> */}
            </Nav>
          )}
          {userProfile && (
            <Nav>
              <LinkContainer to={AppRoutes.LAW_FIRM}>
                <NavItem>
                  <MenuIcon type="bank" /> {polyglot.tr(graphTranslations.firm)}
                </NavItem>
              </LinkContainer>
              <LinkContainer to={AppRoutes.MY_DIAGRAMS}>
                <NavItem>
                  <MenuIcon type="folder-open" />
                  {polyglot.tr(graphTranslations.charts)}
                </NavItem>
              </LinkContainer>
              <LinkContainer to={AppRoutes.FILL_NEW_DIAGRAM}>
                <NavItem>
                  <MenuIcon type="plus" /> {polyglot.tr(graphTranslations.new)}
                </NavItem>
              </LinkContainer>
            </Nav>
          )}

          <Nav pullRight>
            {!userProfile && (
              <LinkContainer to={AppRoutes.LOGIN}>
                <NavItem>
                  <FaLock /> {polyglot.t('signIn')}
                </NavItem>
              </LinkContainer>
            )}

            {userProfile && (
              <NavDropdown
                title={
                  <span>
                    <img className="user-image" alt="" src={userProfile.picture} />
                    {userProfile.name}
                  </span>
                }
                id="user-nav-dropdown"
              >
                <MenuItem className="pointer-events-none">
                  <div>{polyglot.t('signedInAs')}</div>
                  <div className="boldish">{userProfile.email}</div>
                </MenuItem>
                <MenuItem divider />
                <MenuItem onClick={signOut}>
                  <FaSignOutAlt /> {polyglot.t('signOut')}
                </MenuItem>
              </NavDropdown>
            )}
            <NavItem onClick={switchLanguage}>
              <EarthIcon size={14} />
              {polyglot.t('oppositeLanguage')}
            </NavItem>
          </Nav>
        </Navbar.Collapse>
      </Navbar>
    )
  )
}

interface IStateProps {
  userProfile: {
    name: string
    picture: string
    email: string
  }
  language: string
}

interface IDispatchProps {
  signOut: () => void
  switchLanguage: () => void
}

type INavbarProps = IStateProps & IDispatchProps

const mapStateToProps = (state): IStateProps => ({
  userProfile: state.user.user,
  language: state.language
})

const mapDispatchToProps = (dispatch: Dispatch<IApplicationModel>): IDispatchProps => ({
  switchLanguage: () => dispatch(switchLanguage()),
  signOut: () => dispatch.user.signOut()
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RedoxNavbar)
